import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { notify } from "../../../utils/shared";
const API_URL = process.env.REACT_APP_LISTING_API_URL;
const GET_ALL_USER = `${API_URL}/adminBlogs`;
const APPROVE_BLOG = `${API_URL}/activateBlog`;
const GET_BLOG = `${API_URL}/getAdminBlogById`;

export const getBlog = createAsyncThunk(
  "getBlog",
  async (values: any, { rejectWithValue }) => {
    try {
      const { page, limit ,search=''} = values;
      const { data } = await axios.get(`${GET_ALL_USER}?page=${page}&limit=${limit}&search=${search}`, {});
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const approveBlog = createAsyncThunk(
  "approveBlog",
  async (values: any, { rejectWithValue,dispatch,getState}) => {
    try {
      const store :any = getState();
      const page = store.blogList.page || 1;
      const limit = store.blogList.limit || 10;
      const { _id } = values;
      const { data } = await axios.patch(`${APPROVE_BLOG}/${_id}`, {});
      dispatch(getBlog({page: page,limit:limit}))
      notify(data.responseMessage, 'success');
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const getBlogById = createAsyncThunk(
  "getBlogById",
  async (values: any, { rejectWithValue }) => {
    try {
      const { _id } = values;
      const { data } = await axios.get(`${GET_BLOG}/${_id}`, {});
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);