import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { notify } from "../../../utils/shared";
import { setFormDetails, setShowSessionModal } from "../shared/sharedSlice";
import { store } from "../../store";
const API_URL = process.env.REACT_APP_APPOINTMENT_API_URL;
const GET_SESSIONS = `${API_URL}/session`;
const PARTNER_SESSION = `${API_URL}/partnerSession`;

export const getSessions = createAsyncThunk(
  "getSessions",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { page, limit, search='' } = values;
      const { data } = await axios.get(`${GET_SESSIONS}?page=${page}&limit=${limit}&search=${search}`, {});
      data['page'] = page;
      data['limit'] = limit;
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const addSession = createAsyncThunk(
  "addSession",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { page, limit } = store.getState().sessionList
      const { data } = await axios.post(`${GET_SESSIONS}`, values);
      notify(data.responseMessage, 'success');
      dispatch(setShowSessionModal(false));
      dispatch(getSessions({ page: page, limit: limit }));
      dispatch(setFormDetails({}))
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateSession = createAsyncThunk(
  "updateSession",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const _id = values._id
      delete values._id;
      const { page, limit } = store.getState().sessionList
      const { data } = await axios.put(`${GET_SESSIONS}/${_id}`, values);
      notify(data.responseMessage, 'success');
      dispatch(setShowSessionModal(false));
      dispatch(getSessions({ page: page, limit: limit }));
      dispatch(setFormDetails({}))
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteSession = createAsyncThunk(
  "deleteSession",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { id } = values;
      const { page, limit } = store.getState().sessionList
      const { data } = await axios.delete(`${GET_SESSIONS}/${id}`);
      notify(data.responseMessage, 'success');
      dispatch(getSessions({ page: page, limit: limit }));
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const partnerSessionsList = createAsyncThunk(
  "partnerSessionsList",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { page, limit, id } = values;
      const { data } = await axios.get(`${PARTNER_SESSION}/${id}?page=${page}&limit=${limit}`, {});
      data['page'] = page;
      data['limit'] = limit;
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);