import CategoryIcon from "../../../../app/icons/CategoryIcon";
import DashboardIcon from "../../../../app/icons/DashboardIcon";
import PartnersIcon from "../../../../app/icons/PartnersIcon";
import PsychologistIcon from "../../../../app/icons/PsychologistIcon";
import { AsideMenuItem } from "./AsideMenuItem";
import BlogIcon from "../../../../app/icons/BlogIcon";
import SessionIcon from "../../../../app/icons/SessionIcon";
import TransactionsIcon from "../../../../app/icons/TransactionsIcon";
import LanguageIcon from "../../../../app/icons/LanguageIcon";

export function AsideMenuMain() {
  return (
    <>
      <AsideMenuItem to="/user" icon={<DashboardIcon/>} title="Users" />
      <AsideMenuItem to="/partner" icon={<PartnersIcon/>} title="Partner" />
      <AsideMenuItem to="/speciality" icon={<PsychologistIcon/>} title="Speciality" />
      <AsideMenuItem to="/category" icon={<CategoryIcon/>} title="Category" />
      <AsideMenuItem to="/blog" icon={<BlogIcon/>} title="Blog" />
      <AsideMenuItem to="/session" icon={<SessionIcon/>} title="Session" />
      <AsideMenuItem to="/transactions" icon={<TransactionsIcon/>} title="Transactions" />
      {/* <AsideMenuItem to="/notifications" icon={<BlogIcon/>} title="Notifications" /> */}
      {window.location.pathname === '/user/change-password' && <AsideMenuItem to="/user" icon={<PartnersIcon/>} title="Change Password" />}
      {window.location.pathname === '/setting/expiry' && <AsideMenuItem to="/setting" icon={<BlogIcon/>} title="setting" />}
      {window.location.pathname === '/notifications/list' && <AsideMenuItem to="/notifications" icon={<BlogIcon />} title="notifications" />}
      <AsideMenuItem to="/language" icon={<LanguageIcon />} title="Language" />
    </>
  );
}
